import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-collegy-marketplace',
  templateUrl: './collegy-marketplace.component.html',
  styleUrls: ['./collegy-marketplace.component.css']
})
export class CollegyMarketplaceComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
