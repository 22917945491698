<section class="section-mr">
    <div class="container">
        <div class="row">
            <div class="col-sm-8">
                <div class="blog-left-side">
                      
                <div class="lookbook-gallery">
                    <div class="lookbook-grid" >
                    
                    <div>
                        <figure class="model1">
                            <img src="assets/images/blue-background.svg" />
                        </figure>
                    </div>
                    
                    <div>
                        <figure class="model">
                            <img src="assets/images/blue-background.svg" />
                        </figure>
                        
                        <figure class="model">
                            <img src="assets/images/blue-background.svg" />
                        </figure>
                    </div>

                    </div>
                </div>
                    <h1 class="heading">Neque porro quisquam est qui dolorem </h1>
                    <p class="tags-box1">
                        <span class="article-tags1">UI/UX Interface</span>
                        <span class="article-tags1">UI/UX </span>
                        <span class="article-tags1">UI/UX </span>
                        <span class="article-tags1">UI/UX </span>
                    </p>
                    <p class="desc">The Big Picture project is a transition through impact projects which enables
                        students
                        to participate
                        in social and economic changes both local and global. Students participate in challenging
                        projects,
                        talks and educational projects. Students can create 360 degree impact campaigns in three main
                        categories.</p>
                </div>
            </div>
            <div class="col-sm-4">
                <div class="blog-right-side">
                    <p class="p1">
                        Product Price
                    </p>
                    <p class="p2">$185</p>
                    <p class="p3">Including taxes</p>
                    <button >Buy Now</button>
                </div>
            </div>
        </div>
    </div>

</section>