<section class="reset-password-wrap">
    <div class="reset-form-wrap">
        <form [formGroup]="resetPasswordForm" (ngSubmit)="resetPassword()">
            <div class="reset-form-body">
                <p class="forgot-password-heading">Reset Password</p>
                <div class="form-group">
                    <input type="password" formControlName="password" class="form-control field-height" placeholder="Enter New Password" required>
                    <app-input-validation [isShowErrorMsg]="showErrorMessage" [control]="resetPasswordForm.controls.password">
                    </app-input-validation>
                </div>
                <div class="form-group">
                    <input type="password" formControlName="confirmPassword" class="form-control field-height" placeholder="Confirm Password" required>
                    <app-input-validation [isShowErrorMsg]="showErrorMessage" [control]="resetPasswordForm.controls.confirmPassword">
                    </app-input-validation>
                </div>
                <button type="submit" class="reset-password-btn">Reset Password</button>
            </div>
        </form>
    </div>
</section>