export enum ImageSource {
    AVATAR = 'avatar',
    BANNER_IMAGE = 'banner_image',
    PROJECT_BANNER_IMAGE = "project_banner_image",
    PROFILE_DOCS = 'profile_docs',
    BLOGS = 'blogs',
    WEBINARS = 'webinars',
    RESUME='resume',
    COLLEGYFEED='postImageUrl',
    GROUPICON='groupIcon',

}