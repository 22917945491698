<div class="container-fluid no-pad">
    <div class="collegey-magazine-wrapper">
        <div class="container">
            <div class="row">
                <div class="col-md-7 col-sm-5  col-xs-12">
                    <div class="collegey-magazine-text">
                        <p class="collegey-magazine-title">Subcribe to our Collegey Newsletter</p>
                        <p class="collegey-magazine-subtitle">Get the latest updates and news lorem ipsum dolor sit
                            amet.
                        </p>
                    </div>
                </div>
                <div class="col-md-5 col-sm-7 col-xs-12">
                    <div class="collegey-graphics">
                        <div>
                            <label>Enter Email ID</label>
                            <input type=" text" class="form-control" value="admin@gmail.com"
                                aria-label="admin@gmail.com">
                        </div>
                        <button class="btn" type="button">Subscribe</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>