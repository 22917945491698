import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-marketplace-details',
  templateUrl: './marketplace-details.component.html',
  styleUrls: ['./marketplace-details.component.css']
})
export class MarketplaceDetailsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
