<div class="page-not-found-wrap">
    <div class="container">
        <div class="row">
            <div class="col-md-12 col-sm-12">
                <div class="page-not-found-box">
                    <img src="/assets/images/404.svg" alt="Page not found image">
                    <p class="page-not-found">Oops, we can't find the page you're looking for.</p>
                    <button type="button" class="go-back" [routerLink]="['/']">Go Back</button>
                </div>
            </div>
        </div>
    </div>
</div>