<section class="resources-wrap">
    <div class="top-section">
        <div class="terms-header">
            <div class="col-12 col-md-12">
                <!-- <h6>Careers at Collegey</h6>
                <h1>Thank you for your interest in Collegey</h1>
                <p>We are a diverse team with an open and collaborative work culture.</p> -->
                <h6>{{dynamicValue.mainTitle}}</h6>
                <h1>{{dynamicValue.title}}</h1>
                <p>{{dynamicValue.subTitle}}</p>
            </div>
        </div>
    </div>
    <div class="terms-header" style="background-image: url({{dynamicValue?.bannerImg}});">
        <div class="container-fluid">
            <div class="row">
                <div class="col-12 col-md-12">
                    <!-- <h5>Our focus is on impact, project-based learning, and interdisciplinary, global collaborations.
                    </h5> -->
                    <h5>{{dynamicValue.imgSubTitle}}</h5>
                </div>
            </div>
        </div>
    </div>
    <div class="thanks-div">
        <div class="container-fluid">
            <div class="row">
                <div class="col-12 col-md-5">
                    <!-- <h6>We invite you to share how you would like to work with us.</h6>
                    <p>Briefly tell us what role do you see yourself in at Collegey and what outcomes will drive you.
                        Below
                        is a quick form for you to add your responses.</p>
                    <p>Our team will follow up.</p> -->
                    <div class="clg-investDes" [innerHTML]="dynamicValue?.description"></div>
                </div>
                <div class="col-12 col-md-7">
                    <div class="lead-form container">
                        <p class="heading">Please fill in the required details</p>
                        <form class="login-form row" [formGroup]="collegeyCareerform">
                            <div class="group col-sm-6">
                                <div>
                                    <input type="text" required="required" formControlName="name" placeholder="Name*"/>
                                </div>
                                <span *ngIf="submitted && f.name.errors" class="error">
                                    <span *ngIf="submitted && f.name.errors" class="error">
                                        <span *ngIf="f.name.errors.required">Name is required .</span>
                                    </span>
                                </span>
                            </div>
                            <div class="group col-sm-6">
                                <div>
                                    <input type="text" required="required" formControlName="emailId" placeholder="Email ID*"/>
                                </div>
                                <span *ngIf="submitted && f.emailId.errors" class="error">
                                    <span *ngIf="f.emailId.errors.required">Email Id is required .</span>
                                    <span *ngIf="f.emailId.errors.pattern">Email Id is invalid .</span>
                                </span>
                            </div>
                            <div class="group col-sm-6">
                                <div>
                                    <input type="text" required="required" formControlName="city" placeholder="City*"/>
                                </div>
                                <span *ngIf="submitted && f.city.errors" class="error">
                                    <span *ngIf="f.city.errors.required">City is required .</span>
                                </span>
                            </div>
                            <div class="group col-sm-6">
                                <div>
                                    <input type="text" required="required" formControlName="country" placeholder="Country*"/>
                                </div>
                                <span *ngIf="submitted && f.country.errors" class="error">
                                    <span *ngIf="f.country.errors.required">Country is required .</span>
                                </span>
                            </div>
                            <div class="group col-sm-6">
                                <!-- <div>
                                  <select formControlName="countryCode" required="required"  class="clg-countryCode-select">
                                    <option value=""  selected>Select Country Code</option>
                                    <option [value]="code.value" *ngFor="let code of countryPhoneCode">{{code.label}}</option>
                                  </select>
                                </div> -->
                                <div class="selectedCountryCode">      
                                      <ng-select [(ngModel)]="selectedCountryCode" formControlName="countryCode" class="clg-countryCode-select">
                                          <ng-option [value]="">Select Country Code</ng-option>
                                          <ng-option *ngFor="let item of countryPhoneCode" [value]="item?.value">
                                           {{item?.label}}
                                           </ng-option>
                                      </ng-select>
                                  </div>
                                <span *ngIf="submitted && f.countryCode.errors" class="error">
                                    <span *ngIf="f.countryCode.errors.required">Country Code is required .</span>
                                </span>
                            </div>
                            <div class="group col-sm-6">
                                <div>
                                    <input type="text" required="required" formControlName="cellNumber" placeholder="Cell Phone*"/>
                                </div>
                                <span *ngIf="submitted && f.cellNumber.errors" class="error">
                                    <span *ngIf="f.cellNumber.errors.required">Cell Number is required .</span>
                                    <span *ngIf="f.cellNumber.errors.pattern">Cell Number is invalid .</span>
                                </span>
                            </div>
                            <div class="group col-sm-6">
                                <div>
                                    <input type="text" required="required" formControlName="linkedinId" placeholder="Linkedin profile ID*"/>
                                </div>
                                <span *ngIf="submitted && f.linkedinId.errors" class="error">
                                    <span *ngIf="f.linkedinId.errors.required">Linkedin Id is required .</span>
                                    <span *ngIf="f.linkedinId.errors.pattern">Linkedin Id is invalid .</span>
                                </span>
                            </div>
                            <div class="group col-sm-6">
                                <div>
                                    <input type="text" required="required" formControlName="expertise" placeholder="Two areas of expertise*"/>
                                </div>
                                <span *ngIf="submitted && f.expertise.errors" class="error">
                                    <span *ngIf="f.expertise.errors.required">Expertise is required .</span>
                                </span>
                            </div>
                            <div class="group col-sm-6">
                                <div>
                                    <input type="text" required="required" formControlName="workTitle" placeholder="Expected work title*"/>
                                </div>
                                <span *ngIf="submitted && f.workTitle.errors" class="error">
                                    <span *ngIf="f.workTitle.errors.required">WorkTitle is required .</span>
                                </span>
                            </div>
                            <div class="group col-sm-12 col-lg-6 no-md-right-pad no-sm-right-pad">
                                <div>
                                    <input type="text" required="required" formControlName="outcome" placeholder="Two outcomes that you can produce*"/>
                                </div>
                                <span *ngIf="submitted && f.outcome.errors" class="error">
                                    <span *ngIf="f.outcome.errors.required">Outcome is required .</span>
                                </span>
                            </div>
                            <div class="form-group col-sm-12 col-lg-3">
                                <div class="image-upload no-xs-pad">
                                    <label for="file-input-resume">
                                        <img src="assets/images/upload.svg" width="20"
                                            *ngIf="completeNameDocumentList.length <= 0" />
                                        <p class="kyc-label" *ngIf="completeNameDocumentList.length <= 0">UPLOAD RESUME
                                        </p>
                                        <p class="kyc-label" *ngIf="completeNameDocumentList.length > 0">
                                            {{completeNameDocumentList[0]}}</p>
                                    </label>
                                    <input #chooseFilesResume id="file-input-resume" type="file"
                                        (change)="onSelectDocument($event)" />
                                </div>
                                <span *ngIf="submitted && f.resume.errors" class="error">
                                    <span *ngIf="f.resume.errors.required">Resume is required .</span>
                                </span>
                            </div>
                            <div class="image-upload no-xs-pad">

                                <img (click)="onRemoveDocument(0)" src="assets/images/cancel.png" width="20"
                                    *ngIf="completeNameDocumentList.length > 0" />
                            </div>
                            <div class="col-sm-12 no-xs-pad">
                                <button type="submit" class="btn btn-primary btn-block login-btn"
                                    (click)="save()">Submit</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>