<section class="resources-wrap">
    <div class="terms-header">
        <div class="container">
            <div class="row">
                <div class=" col-md-12 col-lg-12 col-xs-12">
                    <div class="clg-cancelPayment">
                    <h1><i class="fa fa-times" aria-hidden="true"></i></h1>
                    <h3>Your Payment is Canceled</h3>
                </div>
                </div>
            </div>
        </div>
    </div>
</section>