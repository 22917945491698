<div class="lead-form-wrapper">
  <div class="lead-form container">
    <div class="lead-form-wrapper" style="border:none !important">
      <form class="login-form row" style="margin:22px; padding-top:25px;" [formGroup]="changePwd">
        
        <div class="col-sm-12" style="padding-right: 13px;padding-left: 10px;">
          <h5>RESET PASSWORD</h5>
        </div>

        <div class="group col-sm-12" style="padding-right: 13px;padding-left: 10px;">
          <div>
            <input type="password" formControlName="oldPwd" required="required" />
            <label style="left:18px;">Old Password*</label>
          </div>
          <span *ngIf="submitted && hasError('oldPwd','required')" class="alert-danger">Please Enter Old password</span>
        </div>
        
        <div class="group col-sm-12" style="padding-right: 15px; padding-left: 10px;">
          <div >
            <input type="password" formControlName="newPwd" required="required" />
            <label style="left:18px;">New Password (Atleast 8 characters) *</label>
          </div>
          <span *ngIf="submitted && hasError('newPwd','required')" class="alert-danger">Please Enter password</span>
          <span *ngIf="submitted && hasError('newPwd','minlength')" class="alert-danger">Please Enter 8 Characters</span>
        </div>
        
        <div class="group col-sm-12" style="padding-right: 13px; padding-left: 10px;">
          <div>
            <input type="password" formControlName="confirmPwd" required="required" />
            <label style="left:18px;">Confirm Password*</label>
          </div>
          <span *ngIf="submitted && hasError('confirmPwd','required')" class="alert-danger">Please Enter password</span>
          <span *ngIf="changePwd.controls.confirmPwd.hasError('confirmedValidator')" class="alert-danger">passwords do not match</span>
        </div>

        <div class="group col-sm-12" style="padding-right: 13px; padding-left: 10px;">
          <ngx-recaptcha2
          [siteKey]="siteKey" formControlName="recaptcha" > </ngx-recaptcha2>
          <span *ngIf="submitted && hasError('recaptcha','required')" class="alert-danger">Recaptcha is
            required</span>
        </div>
        
      </form>
    <div class="btn-wrapper">
        <button class="submitbutton" (click)="onSubmitPsd()">SUBMIT</button>
    </div>
    </div>
  </div>
</div>

