<section class="resources-wrap">
    <div class="row">
        <div class="container">
            <div class="row banner-blog">
                <!-- image box for mobile hidden above sm -->
                <div class="col-sm hide-md no-pad">
                    <img src="/assets/images/default-blog.svg" class="full-width">
                </div>
                <div class="col-sm">
                    <div class="webinar-content-box">
                        <p class="tags-box">
                            <span class="article-tags">tag</span>
                            <span class="article-tags">targ</span>
                            <span class="article-tags">qwwqtag</span>
                            <span class="article-tags">kjktag</span>
                            <span class="article-tags">54tretag</span>
                        </p>
                        <a class="post-title webinar-title" href="javascript:void(0)">title</a>
                        <div class="post-date-like">
                            <span class="blog-date-span">
                                <img src="assets/images/new-star.svg"> Posted by
                                <span>author</span> on 22 july
                            </span>
                            <span class="hide-xs">
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </span>
                            <!-- <span class="blog-like-span">
                                <img src="assets/images/like.svg"> <span> 1,556 Likes</span>
                            </span> -->
                        </div>
                        <p class="post-description webinar-desc top-gap">short description</p>
                        <a style="
                            cursor: default;"
                         class="read-more">Read More <span> ></span></a>
                        <button class="btn slider-btn previous-btn hide-xs">
                            Previous
                        </button>
                    </div>
                </div>
                <!-- image box for above sm -->
                <div class="col-sm hide-xs" style="padding: 0;">
                    <img src="/assets/images/default-blog.svg" class="full-width">
                    <button class="btn slider-btn next-btn" >
                        Next
                    </button>
                </div>
                <!-- next prevoius button box for mobile hidden above sm -->
                <div class="btnOnMobile hide-md">
                    <button class="btn slider-btn previous-btn" >
                        Previous
                    </button>
                    <button class="btn slider-btn next-btn">
                        Next
                    </button>
                </div>
            </div>
        </div>

        <div class="container">
            <div class="resources-content">
                <div class="row resources-tabs underline-from-left">
                    <div class="col-md-8 col-sm-12">
                        <div class="tabs-wrap">
                            <div class="container">
                                <div class="row">
                                    <div class="col-md-6 col-sm-12">
                                        <div class="webinar-box article-box">
                        
                                            <div class="webinar-image-box">
                                                <img class="zoom-image" src="/assets/images/default-blog.svg" alt="Article Image">
                                            </div>
                                            <div class="webinar-content-box">
                                                <a class="post-title webinar-title" href="javascript:void(0)">article title</a>
                                                <p class="post-description webinar-desc top-gap">article short_description</p>
                                                <p class="tags-box"><span class="article-tags">tag</span>
                                                    <span class="article-tags">targ</span>
                                                    <span class="article-tags">qwwqtag</span>
                                                    <span class="article-tags">kjktag</span>
                                                    <span class="article-tags">54tretag</span></p>
                                                <p class="posted-date top-gap"><span  class="blog-date-span">By article author </span><span>22 july</span></p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-6 col-sm-12">
                                        <div class="webinar-box article-box">
                        
                                            <div class="webinar-image-box">
                                                <img class="zoom-image" src="/assets/images/default-blog.svg" alt="Article Image">
                                            </div>
                                            <div class="webinar-content-box">
                                                <a class="post-title webinar-title" href="javascript:void(0)">article title</a>
                                                <p class="post-description webinar-desc top-gap">article short_description</p>
                                                <p class="tags-box"><span class="article-tags">tag</span>
                                                    <span class="article-tags">targ</span>
                                                    <span class="article-tags">qwwqtag</span>
                                                    <span class="article-tags">kjktag</span>
                                                    <span class="article-tags">54tretag</span></p>
                                                <p class="posted-date top-gap"><span  class="blog-date-span">By article author </span><span>22 july</span></p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-6 col-sm-12">
                                        <div class="webinar-box article-box">
                        
                                            <div class="webinar-image-box">
                                                <img class="zoom-image" src="/assets/images/default-blog.svg" alt="Article Image">
                                            </div>
                                            <div class="webinar-content-box">
                                                <a class="post-title webinar-title" href="javascript:void(0)">article title</a>
                                                <p class="post-description webinar-desc top-gap">article short_description</p>
                                                <p class="tags-box"><span class="article-tags">tag</span>
                                                    <span class="article-tags">targ</span>
                                                    <span class="article-tags">qwwqtag</span>
                                                    <span class="article-tags">kjktag</span>
                                                    <span class="article-tags">54tretag</span></p>
                                                <p class="posted-date top-gap"><span  class="blog-date-span">By article author </span><span>22 july</span></p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-6 col-sm-12">
                                        <div class="webinar-box article-box">
                        
                                            <div class="webinar-image-box">
                                                <img class="zoom-image" src="/assets/images/default-blog.svg" alt="Article Image">
                                            </div>
                                            <div class="webinar-content-box">
                                                <a class="post-title webinar-title" href="javascript:void(0)">article title</a>
                                                <p class="post-description webinar-desc top-gap">article short_description</p>
                                                <p class="tags-box"><span class="article-tags">tag</span>
                                                    <span class="article-tags">targ</span>
                                                    <span class="article-tags">qwwqtag</span>
                                                    <span class="article-tags">kjktag</span>
                                                    <span class="article-tags">54tretag</span></p>
                                                <p class="posted-date top-gap"><span  class="blog-date-span">By article author </span><span>22 july</span></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        
                        </div>
                        
                        <!-- <div class="pagination-container" *ngIf="totalBlogs &&  hidePagination">
                            <pagination [totalItems]="totalBlogs" [itemsPerPage]="12" [(ngModel)]="currentPage" (pageChanged)="loadMore($event)">
                            </pagination>
                        </div> -->
                    </div>
                    <div class="col-md-4 sidebr-articles" style="margin-top: 40px;">
                        <div class="recent-articles">
                            <h5>Recent Articles</h5>
                            <ul>
                                <li>
                                    <a href="">Poland and Germany to expand exchange</a>
                                </li>
                                <li>
                                    <a href="">Australia must diversify further, former PM suggests</a>
                                </li>
                                <li>
                                    <a href="">Poland and Germany to expand exchange</a>
                                </li>
                                <li>
                                    <a href="">Australia must diversify further, former PM suggests</a>
                                </li>
                                <li>
                                    <a href="">Poland and Germany to expand exchange</a>
                                </li>
                            </ul>
                        </div>
                        <div class="recent-articles">
                            <h5>Popular Categories</h5>
                            <ul>
                                <li>
                                    <a href="">Lorem Lorem</a>
                                </li>
                                <li>
                                    <a href="">Australia Lorem Lorems</a>
                                </li>
                                <li>
                                    <a href="">Lorem Lorem and Germany</a>
                                </li>
                                <li>
                                    <a href="">Lorem Lorem furtherPM suggests</a>
                                </li>
                                <li>
                                    <a href="">PolLorem Loremand and Lorem Lorem to expand exchange</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>