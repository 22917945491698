<div class="tabs-wrap">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-12" *ngFor="let article of articles;">
                <div class="webinar-box article-box" (click)="onNavigate(article.slug)">

                    <div class="webinar-image-box">
                        <img class="zoom-image" [src]="commonService.imagePathMaker(article.image, '/assets/images/default-blog.svg')" alt="Article Image">
                    </div>
                    <div class="webinar-content-box">
                        <a class="post-title webinar-title" href="javascript:void(0)">{{article.title}}</a>
                        <p class="post-description webinar-desc top-gap">{{article.short_description}}</p>
                        <p class="tags-box"><span class="article-tags" *ngFor="let tag of article.tags" (click)="getBlogsByTag(tag)">{{tag}}</span></p>
                        <p class="posted-date top-gap"><span *ngIf="article.author" class="blog-date-span">By {{article.author}} </span><span>{{article.createdAt | date}}</span></p>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>

<div class="pagination-container" *ngIf="totalBlogs &&  hidePagination">
    <pagination [totalItems]="totalBlogs" [itemsPerPage]="12" [(ngModel)]="currentPage" (pageChanged)="loadMore($event)">
    </pagination>
</div>