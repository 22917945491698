<section class="student-profile-wrapper">
    
    <div class="container">
        <div class="row" style="margin-bottom: 30px;">
            <div class="col-sm-4 student-profile no-sm-pad">
                <div style="display: flex;align-items: center;">
                    <div style="position: relative;">
                        <img src="assets/images/profile-picture.png" class="user-img" alt="">
                        <div class="file-input">
                            <input type="file" name="file-input" id="file-input" class="file-input__input" />
                            <label class="file-input__label" for="file-input">
                                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                                    xmlns:svgjs="http://svgjs.com/svgjs" version="1.1" width="512" height="512" x="0"
                                    y="0" viewBox="0 0 512 512" style="enable-background:new 0 0 512 512"
                                    xml:space="preserve" class="">
                                    <g>
                                        <path xmlns="http://www.w3.org/2000/svg"
                                            d="m256 0c-141.164062 0-256 114.835938-256 256s114.835938 256 256 256 256-114.835938 256-256-114.835938-256-256-256zm0 0"
                                            fill="#ff971a" data-original="#2196f3" class="" />
                                        <path xmlns="http://www.w3.org/2000/svg"
                                            d="m368 277.332031h-90.667969v90.667969c0 11.777344-9.554687 21.332031-21.332031 21.332031s-21.332031-9.554687-21.332031-21.332031v-90.667969h-90.667969c-11.777344 0-21.332031-9.554687-21.332031-21.332031s9.554687-21.332031 21.332031-21.332031h90.667969v-90.667969c0-11.777344 9.554687-21.332031 21.332031-21.332031s21.332031 9.554687 21.332031 21.332031v90.667969h90.667969c11.777344 0 21.332031 9.554687 21.332031 21.332031s-9.554687 21.332031-21.332031 21.332031zm0 0"
                                            fill="#fafafa" data-original="#fafafa" />
                                    </g>
                                </svg>
                            </label>
                        </div>
                    </div>
                    <div class="">
                        <p>Welcome</p>
                        <h5>Ashish</h5>
                    </div>
                </div>
            </div>
            <div class="col-sm-8 portfolio-progress-wrapper">
                <div class="portfolio-wrapper">
                    <div class="portfolio-user">
                        <p>PORTFOLIO STRENGTH:</p>
                        <h5>Beginner</h5>
                    </div>
                    <div class="portfolio-progress">
                        <ul>
                            <li class="is-active">
                                <hr>
                                <div>
                                    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                                        xmlns:svgjs="http://svgjs.com/svgjs" version="1.1" width="512" height="512"
                                        x="0" y="0" viewBox="0 0 24 24" style="enable-background:new 0 0 512 512"
                                        xml:space="preserve">
                                        <g>
                                            <g xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="m9.707 19.121c-.187.188-.442.293-.707.293s-.52-.105-.707-.293l-5.646-5.647c-.586-.586-.586-1.536 0-2.121l.707-.707c.586-.586 1.535-.586 2.121 0l3.525 3.525 9.525-9.525c.586-.586 1.536-.586 2.121 0l.707.707c.586.586.586 1.536 0 2.121z"
                                                    fill="#ffffff" data-original="#000000" />
                                            </g>
                                        </g>
                                    </svg>
                                </div>

                            </li>
                            <li class="is-active">
                                <hr>
                                <div>
                                    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                                        xmlns:svgjs="http://svgjs.com/svgjs" version="1.1" width="512" height="512"
                                        x="0" y="0" viewBox="0 0 24 24" style="enable-background:new 0 0 512 512"
                                        xml:space="preserve">
                                        <g>
                                            <g xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="m9.707 19.121c-.187.188-.442.293-.707.293s-.52-.105-.707-.293l-5.646-5.647c-.586-.586-.586-1.536 0-2.121l.707-.707c.586-.586 1.535-.586 2.121 0l3.525 3.525 9.525-9.525c.586-.586 1.536-.586 2.121 0l.707.707c.586.586.586 1.536 0 2.121z"
                                                    fill="#ffffff" data-original="#000000" />
                                            </g>
                                        </g>
                                    </svg>
                                </div>
                            </li>
                            <li class="">
                                <hr>
                                <div>
                                    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                                        xmlns:svgjs="http://svgjs.com/svgjs" version="1.1" width="512" height="512"
                                        x="0" y="0" viewBox="0 0 24 24" style="enable-background:new 0 0 512 512"
                                        xml:space="preserve">
                                        <g>
                                            <g xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="m9.707 19.121c-.187.188-.442.293-.707.293s-.52-.105-.707-.293l-5.646-5.647c-.586-.586-.586-1.536 0-2.121l.707-.707c.586-.586 1.535-.586 2.121 0l3.525 3.525 9.525-9.525c.586-.586 1.536-.586 2.121 0l.707.707c.586.586.586 1.536 0 2.121z"
                                                    fill="#ffffff" data-original="#000000" />
                                            </g>
                                        </g>
                                    </svg>
                                </div>
                            </li>
                            <li>
                                <hr>
                                <div>
                                    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                                        xmlns:svgjs="http://svgjs.com/svgjs" version="1.1" width="512" height="512"
                                        x="0" y="0" viewBox="0 0 24 24" style="enable-background:new 0 0 512 512"
                                        xml:space="preserve" class="">
                                        <g>
                                            <g xmlns="http://www.w3.org/2000/svg">
                                                <g>
                                                    <path
                                                        d="m15.399 17c-.083 0-.166-.021-.242-.062l-3.157-1.746-3.157 1.745c-.169.094-.374.083-.53-.029-.156-.11-.236-.3-.205-.489l.608-3.731-2.573-2.638c-.131-.134-.176-.33-.117-.508.06-.178.214-.307.399-.335l3.537-.541 1.585-3.379c.166-.351.74-.351.906 0l1.585 3.379 3.537.541c.186.028.34.158.399.335.059.178.014.374-.117.508l-2.573 2.638.608 3.731c.031.189-.049.379-.205.489-.085.061-.186.092-.288.092zm-3.399-2.879c.083 0 .167.021.242.062l2.498 1.381-.483-2.964c-.025-.157.024-.316.136-.43l2.07-2.122-2.839-.434c-.165-.025-.307-.131-.378-.282l-1.246-2.655-1.246 2.657c-.071.151-.213.257-.378.282l-2.839.434 2.07 2.122c.111.114.161.273.136.43l-.483 2.962 2.498-1.381c.075-.041.159-.062.242-.062z"
                                                        fill="#ffffff" data-original="#000000" class="" />
                                                </g>
                                            </g>
                                            <g xmlns="http://www.w3.org/2000/svg">
                                                <g>
                                                    <path
                                                        d="m12 24c-.043 0-.086-.005-.127-.017-.099-.025-9.873-2.703-9.873-11.804v-8.179c0-.209.131-.397.327-.469l9.5-3.5c.111-.041.234-.041.346 0l9.5 3.5c.196.072.327.26.327.469v8.179c0 9.102-9.774 11.779-9.873 11.805-.041.011-.084.016-.127.016zm-9-19.651v7.83c0 7.882 7.85 10.466 9 10.802 1.15-.336 9-2.92 9-10.802v-7.83l-9-3.316z"
                                                        fill="#ffffff" data-original="#000000" class="" />
                                                </g>
                                            </g>
                                        </g>
                                    </svg>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
                <p class="port-desc">A complete portfolio enhances your chances of building winning applications for
                    colleges and
                    universities, and help us recommend best-fit projects for you.</p>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-4 portfolio-steps no-sm-pad">
                <p class="steps-p">Let’s build your portfolio together!</p>
                <ul>
                    <li>
                        <a class="completed">
                            <div>
                                <p class="progress-icon"></p>
                            </div>
                            <span>Location</span>
                        </a>
                    </li>
                    <li>
                        <a class="completed">
                            <hr>
                            <div>
                                <p class="progress-icon"></p>
                            </div>
                            <span>Education</span>
                        </a>
                    </li>
                    <li>
                        <a class="completed">
                            <hr>
                            <div>
                                <p class="progress-icon"></p>
                            </div>
                            <span>Interests</span>
                        </a>
                    </li>
                    <li>
                        <a class="active">
                            <hr>
                            <div>
                                <p class="progress-icon"></p>
                            </div>
                            <span>Favorites</span>
                        </a>
                    </li>
                    <li>
                        <a>
                            <hr>
                            <div>
                                <p class="progress-icon"></p>
                            </div>
                            <span>Project & Accomplishments</span>
                        </a>
                    </li>
                    <li>
                        <a>
                            <hr>
                            <div>
                                <p class="progress-icon"></p>
                            </div>
                            <span>Future Education Plans</span>
                        </a>
                    </li>
                    <li>
                        <a>
                            <hr>
                            <div>
                                <p class="progress-icon"></p>
                            </div>
                            <span>Preferences</span>
                        </a>
                    </li>
                    <li>
                        <a>
                            <hr>
                            <div>
                                <p class="progress-icon"></p>
                            </div>
                            <span>Personal</span>
                        </a>
                    </li>
                </ul>
            </div>
            <div class="col-sm-8 no-pad">
                <div class="lead-form-wrapper">
                    <div class="lead-form container">
                        <p class="steps-login">1 of 8</p>
                        <p class="heading">Location</p>
                        <form class="login-form row">
                            <div class="group col-sm-6">
                                <div>
                                    <label class="select-label">Country*</label>
                                    <select>
                                        <option value="1">Select Country*</option>
                                        <option value="2">a</option>
                                        <option value="1">b</option>
                                        <option value="1">c</option>
                                    </select>
                                </div>
                            </div>
                            <div class="group col-sm-6">
                                <div>
                                    <label class="select-label">State*</label>
                                    <select>
                                        <option value="1">Select State*</option>
                                        <option value="2">a</option>
                                        <option value="1">b</option>
                                        <option value="1">c</option>
                                    </select>
                                </div>
                            </div>
                            <div class="group col-sm-6">
                                <div>
                                    <label class="select-label">City*</label>
                                    <select>
                                        <option value="1">Select City*</option>
                                        <option value="2">a</option>
                                        <option value="1">b</option>
                                        <option value="1">c</option>
                                    </select>
                                </div>
                            </div>
                            <div class="group col-sm-6">
                                <div>
                                    <input type="text" required="required" value="Indian" />
                                    <label>Citizenship*</label>
                                </div>
                            </div>

                        </form>
                    </div>
                    <div class="btn-wrapper">
                        <!-- <button class="backbutton">Back</button> -->
                        <div>
                            <button class="savebtn">Save and Complete later</button>
                            <button class="submitbutton">SAVE & NEXT</button>
                        </div>
                    </div>
                </div>
                <div class="lead-form-wrapper hides">
                    <div class="lead-form container">
                        <p class="steps-login">2 of 8</p>
                        <p class="heading">Education</p>
                        <form class="login-form row">
                            <p>Enter your academic information, current and past. Add all school grades from grade 9
                                onwards. For college, add all years from the first year onwards.</p>
                            <div class="flex-div">
                                <p style="font-size: 15px;">
                                    You are in:
                                </p>
                                <div class="group col-sm-7 col-md-6 col-lg-5 pd-r15">
                                    <div>
                                        <label style="font-size: 15px;" class="select-label">Select option</label>
                                        <select>
                                            <option value="1">School</option>
                                            <option value="2">a</option>
                                            <option value="1">b</option>
                                            <option value="1">c</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <h6 class="school--grade">Your School Details</h6>
                            <div class="group col-sm-6 no-pad">
                                <div>
                                    <label class="select-label label-pad-designleft">Grade level attended at:</label>
                                    <select>
                                        <option value="1">Select grade level</option>
                                        <option value="2">a</option>
                                        <option value="1">b</option>
                                        <option value="1">c</option>
                                    </select>
                                </div>
                            </div>
                            <p style="font-size: 15px;" class="grade-imp-msg">your high school, you can select multiple
                                grades.</p>
                        </form>
                    </div>
                    <div class="btn-wrapper">
                        <button class="backbutton">Back</button>
                        <div>
                            <button class="savebtn">Save and Complete later</button>
                            <button class="submitbutton">SAVE & NEXT</button>
                        </div>
                    </div>
                </div>
                <div class="lead-form-wrapper hides">
                    <div class="lead-form container">
                        <p class="steps-login">2 of 8</p>
                        <p class="heading">Education</p>
                        <form class="login-form row">
                            <p>Enter your academic information, current and past. Add all school grades from grade 9
                                onwards. For college, add all years from the first year onwards.</p>
                            <div class="flex-div">
                                <p style="font-size: 15px;">
                                    You are in:
                                </p>
                                <div class="group col-sm-7 col-md-6 col-lg-5 pd-r15">
                                    <div>
                                        <label style="font-size: 15px;" class="select-label">Select option</label>
                                        <select>
                                            <option value="1">School</option>
                                            <option value="2">a</option>
                                            <option value="1">b</option>
                                            <option value="1">c</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <h6 class="school--grade">Your School Details</h6>
                            <div class="group col-sm-6 col-md-6 no-pad">
                                <div>
                                    <label style="font-size: 15px;" class="select-label label-pad-designleft">Grade level attended at:</label>
                                    <select>
                                        <option value="1">Select grade level</option>
                                        <option value="2">a</option>
                                        <option value="1">b</option>
                                        <option value="1">c</option>
                                    </select>
                                </div>
                            </div>
                            <p style="font-size: 15px;" class="grade-imp-msg">If you did not change your high school, you can select multiple
                                grades.</p>
                            <div class="flex-div">
                                <div class="group col-sm-3 no-pad pd-r15">
                                    <div>
                                        <label style="font-size: 15px;" class="select-label">Start Year</label>
                                        <select>
                                            <option value="1">Select Year</option>
                                            <option value="2" selected>9</option>
                                            <option value="1">b</option>
                                            <option value="1">c</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="group col-sm-3 no-pad ">
                                    <div>
                                        <label style="font-size: 15px;" class="select-label label-pad-designleft">End Year</label>
                                        <select>
                                            <option value="1">Select Year</option>
                                            <option value="2">a</option>
                                            <option value="1">b</option>
                                            <option value="1">c</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="group col-sm-6 no-pad pd-l15">
                                    <div>
                                        <label class="select-label label-pad-designright">High School Board</label>
                                        <select>
                                            <option value="1">Select Board</option>
                                            <option value="2">a</option>
                                            <option value="1">b</option>
                                            <option value="1">c</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div class="group col-sm-6 no-pad">
                                <div>
                                    <label class="select-label">Subjects</label>
                                    <select>
                                        <option value="1">Select upto 5 or add new subject</option>
                                        <option value="2">a</option>
                                        <option value="1">b</option>
                                        <option value="1">c</option>
                                    </select>
                                </div>
                            </div>
                            <div class="group col-sm-6 no-pad pd-l15">
                                <div>
                                    <input type="text" required="required" value="" />
                                    <label class="label-pad-designright">Score grade/GPA</label>
                                </div>
                            </div>
                            <div class="group col-sm-6 no-pad">
                                <div>
                                    <input type="text" required="required" value="" />
                                    <label style="font-size: 15px;" class="">High school name:</label>
                                </div>
                            </div>
                            <div class="group col-sm-6 no-pad pd-l15">
                                <div>
                                    <input type="text" required="required" value="" />
                                    <label style="font-size: 15px;" class="label-pad-designright">High school location (city/state):</label>
                                </div>
                            </div>
                            <div class="group col-sm-6 no-pad add-item-btn">
                                <a href="">+ ADD ANOTHER GRADE</a>
                            </div>
                            <div class="group col-sm-6 no-pad pd-l15 add-item-btn ">
                                <a href="">+ ADD ANOTHER SCHOOL</a>
                            </div>
                        </form>
                    </div>
                    <div class="btn-wrapper">
                        <button class="backbutton">Back</button>
                        <div>
                            <button class="savebtn">Save and Complete later</button>
                            <button class="submitbutton">SAVE & NEXT</button>
                        </div>
                    </div>
                </div>
                <div class="lead-form-wrapper hides">
                    <div class="lead-form container">
                        <p class="steps-login">2 of 8</p>
                        <p class="heading">Education</p>
                        <form class="login-form row">
                            <p>Enter your academic information, current and past. Add all school grades from grade 9
                                onwards. For college, add all years from the first year onwards.</p>
                            <div class="final-education-details">
                                <h3>DAV Public School, Patna</h3>
                                <a href="" class="edit-link">+ ADD ANOTHER GRADE | EDIT</a>
                                <hr>
                                <div class="year-details">
                                    <ul>
                                        <li>
                                            2007 - 2009
                                        </li>
                                        <li>
                                            12th - 59%
                                        </li>
                                    </ul>
                                    <ul>
                                        <li>
                                            CBSE
                                        </li>
                                        <li>
                                            Maths, Physics, Chemistry
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div class="group col-sm-12 no-pad add-item-btn">
                                <a href="">+ ADD ANOTHER HIGH SCHOOL | + ADD COLLEGE</a>
                            </div>
                        </form>
                    </div>
                    <div class="btn-wrapper">
                        <button class="backbutton">Back</button>
                        <div>
                            <button class="savebtn">Save and Complete later</button>
                            <button class="submitbutton">SAVE & NEXT</button>
                        </div>
                    </div>
                </div>
                <div class="lead-form-wrapper hides">
                    <div class="lead-form container">
                        <p class="steps-login">3 of 8</p>
                        <p class="heading">Interests</p>
                        <form class="login-form row">
                            <p>Two quick questions to bring interesting ideas your way</p>
                            <div class="flex-start">
                                <div class="flex-width">
                                    <div class="group col-sm-12 no-pad">
                                        <div>
                                            <label class="select-label label-pad-designleft">What are your main interest
                                                areas?</label>
                                            <select>
                                                <option value="1">Select Interests</option>
                                                <option value="2">a</option>
                                                <option value="1">b</option>
                                                <option value="1">c</option>
                                            </select>
                                        </div>
                                    </div>
                                    <p class="grade-imp-msg grade-imp-msg-height">Select upto 4 interest areas</p>
                                    <ul class="interest-points">
                                        <li>
                                            <a href=""> Graphic
                                                <span>
                                                    <img src="assets/images/close.svg" alt="">
                                                </span>
                                            </a>
                                        </li>
                                        <li>
                                            <a href=""> Graphic Test This
                                                <span>
                                                    <img src="assets/images/close.svg" alt="">
                                                </span>
                                            </a>
                                        </li>
                                        <li>
                                            <a href=""> Graphic
                                                <span>
                                                    <img src="assets/images/close.svg" alt="">
                                                </span>
                                            </a>
                                        </li>
                                        <li>
                                            <a href=""> Graphic Test This
                                                <span>
                                                    <img src="assets/images/close.svg" alt="">
                                                </span>
                                            </a>
                                        </li>
                                        <li>
                                            <a href=""> Graphic
                                                <span>
                                                    <img src="assets/images/close.svg" alt="">
                                                </span>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                                <div class="pd-l15 flex-width">
                                    <div class="group col-sm-12 no-pad">
                                        <div>
                                            <label class="select-label label-pad-designleft">List your favorite
                                                subjects</label>
                                            <select>
                                                <option value="1">List of favourite subjects</option>
                                                <option value="2">a</option>
                                                <option value="1">b</option>
                                                <option value="1">c</option>
                                            </select>
                                        </div>
                                    </div>
                                    <p class="grade-imp-msg grade-imp-msg-height">Select as many as you wish. You can
                                        also add your own
                                        choices</p>
                                    <ul class="interest-points">
                                        <li>
                                            <a href=""> Graphic
                                                <span>
                                                    <img src="assets/images/close.svg" alt="">
                                                </span>
                                            </a>
                                        </li>
                                        <li>
                                            <a href=""> Graphic
                                                <span>
                                                    <img src="assets/images/close.svg" alt="">
                                                </span>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div class="flex-start">
                                <div class="flex-width">
                                    <div class="group col-sm-12">
                                        <p class="textarea-label">What are two or three key problems that you think
                                            Patna has?</p>
                                        <div>
                                            <textarea class="form-control form-control-sm"
                                                placeholder="Type your answer here..."
                                                style="min-height: 44px;"></textarea>
                                        </div>
                                    </div>
                                </div>
                                <div class="pd-l15 flex-width">
                                    <div class="group col-sm-12">
                                        <p class="textarea-label">If you are allowed to work on any 2 projects to
                                            improve Patna,
                                            what will they be?</p>
                                        <div>
                                            <textarea class="form-control form-control-sm"
                                                placeholder="Type your answer here..."
                                                style="min-height: 44px;"></textarea>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div class="btn-wrapper">
                        <button class="backbutton">Back</button>
                        <div>
                            <button class="savebtn">Save and Complete later</button>
                            <button class="submitbutton">SAVE & NEXT</button>
                        </div>
                    </div>
                </div>
                <div class="lead-form-wrapper hides">
                    <div class="lead-form container">
                        <p class="steps-login">4 of 8</p>
                        <p class="heading">Favorites</p>
                        <form class="login-form row">
                            <p>Tell us what you find cool. We will bring more of it.</p>

                            <div class="favorites-details">
                                <div class="full-width">
                                    <p style="margin-bottom: 10px;">Name 2 people who inspire you</p>
                                    <div class="group col-sm-12 groupleftpad">
                                        <div>
                                            <input type="text" required="required" value="" />
                                            <label>Name 1</label>
                                        </div>
                                    </div>
                                    <div class="group col-sm-12">
                                        <div>
                                            <input type="text" required="required" value="" />
                                            <label>Name 2</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="full-width">
                                    <p style="margin-bottom: 10px;padding-left: 10px;">List 2 of your favourite books
                                    </p>
                                    <div class="group col-sm-12 ">
                                        <div>
                                            <input type="text" required="required" value="" />
                                            <label>Book Name 1</label>
                                        </div>
                                    </div>
                                    <div class="group col-sm-12 grouprightpad">
                                        <div>
                                            <input type="text" required="required" value="" />
                                            <label>Book Name 2</label>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="favorites-details">
                                <div class="full-width">
                                    <p style="margin-bottom: 10px;">List 2 of your favourite documentaries /movies</p>
                                    <div class="group col-sm-12 groupleftpad">
                                        <div>
                                            <input type="text" required="required" value="" />
                                            <label>Movie Name 1</label>
                                        </div>
                                    </div>
                                    <div class="group col-sm-12">
                                        <div>
                                            <input type="text" required="required" value="" />
                                            <label>Movie Name 2</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="full-width">
                                    <p style="margin-bottom: 10px;padding-left: 10px;">List 2 of your favorite
                                        activities on the internet.
                                    </p>
                                    <div class="group col-sm-12 ">
                                        <div>
                                            <input type="text" required="required" value="" />
                                            <label>Activity 1</label>
                                        </div>
                                    </div>
                                    <div class="group col-sm-12 grouprightpad">
                                        <div>
                                            <input type="text" required="required" value="" />
                                            <label>Activity 1</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="favorites-details">
                                <div class="full-width">
                                    <p style="margin-bottom: 10px;">Your 3 favourite websites</p>
                                    <div class="group col-sm-12 groupleftpad">
                                        <div>
                                            <input type="text" required="required" value="" />
                                            <label>Website 1</label>
                                        </div>
                                    </div>
                                    <div class="group col-sm-12 groupleftpad">
                                        <div>
                                            <input type="text" required="required" value="" />
                                            <label>Website 2</label>
                                        </div>
                                    </div>
                                    <a href="" class="add-more-website-btn">+ADD MORE</a>
                                </div>
                                <div class="full-width">
                                    <p style="margin-bottom: 10px;padding-left: 10px;visibility: hidden;">
                                        Services </p>
                                    <div class="group col-sm-12 ">
                                        <div>
                                            <input type="text" required="required" value="" />
                                            <label>Book Name 1</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div class="btn-wrapper">
                        <button class="backbutton">Back</button>
                        <div>
                            <button class="savebtn">Save and Complete later</button>
                            <button class="submitbutton">SAVE & NEXT</button>
                        </div>
                    </div>
                </div>
                <div class="lead-form-wrapper hides">
                    <div class="lead-form container">
                        <p class="steps-login">5 of 8</p>
                        <p class="heading">Project & Accomplishments</p>
                        <form class="login-form row">
                            <p>All the lovely stuff you have done that we can promote.</p>
                            <div class="final-education-details">
                                <h3>Describe any project/class project that you have done</h3>
                                <a href="" class="edit-link">+ ADD PROJECT</a>
                            </div>
                            <div class="final-education-details">
                                <h3>Share any writing sample, essays or any of your published or in-progress work that
                                    you wish to share</h3>
                                <a href="" class="edit-link">+ ADD</a>
                            </div>
                            <div class="final-education-details">
                                <h3>Upload your
                                    Letters of Recommendation (LoRs) or References.</h3>
                                <a href="" class="edit-link">+ ADD</a>
                            </div>
                            <div class="final-education-details">
                                <h3>Let’s showcase your credentials</h3>
                                <p style="margin-bottom: 0;">Add awards/certificates/
                                    participations/internship/volunteer experience/online
                                    learning</p>
                                <a href="" class="edit-link">+ ADD</a>
                            </div>
                        </form>
                    </div>
                    <div class="btn-wrapper">
                        <button class="backbutton">Back</button>
                        <div>
                            <button class="savebtn">Save and Complete later</button>
                            <button class="submitbutton">SAVE & NEXT</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>