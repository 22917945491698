
<a href="#"  class="fb btn" (click)="socialSignIn('facebookLogin')">
  <img src="assets/images/facebook.png" width="16" alt="" /> Facebook
</a>

<a href="#"  class="google btn" (click)="socialSignIn('googleLogin')">
 <img src="assets/images/google.png" width="16" alt="" /> Google
</a>
  
<a href="#"  class="google btn" (click)="loginWithlinkedin()">
  <img src="assets/images/linkidein.png" width="22" alt="" /> LinkedIn
</a>
