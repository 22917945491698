<section class="resources-wrap">
    <div class="row">
        <div class="container-fluid no-pad">
            <div class="col-md-12 col-sm-12 terms-header">
                <h1>Collegey Fellowships</h1>
            </div>
        </div>
        <div class="col-md-12">
            <div class="resources-content">
                <div class="row resources-tabs underline-from-left">
                    <div class="col-md-12 col-sm-12">
                        <div class="tabs-wrap">
                            <div class="container">
                                <div class="row">
                                    <div class="col-lg-4 col-md-6 col-sm-12">
                                        <div class="webinar-box article-box">
                                            <!-- <a [href]="programme.redirect_link" target="_blank">
                                            </a> -->
                                            <div class="webinar-image-box">
                                                <img class="zoom-image"  src="/assets/images/default-blog.svg" alt="Programme Image">
                                            </div>
                                            <div class="webinar-content-box">
                                                <a class="post-title webinar-title" href="/fellowships/fellowships-title">Neque porro quisquam est qui dolorem ipsum quia dolor sit amet</a>
                                                <p class="post-description webinar-desc top-gap">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed at blandit ipsum. Curabitur laoreet venenatis lacus sed volutpat. Aenean vitae dictum sapien, in consectetur ex. Mauris sit amet tincidunt ex. In ac auctor dolor, eget dictum orci. Praesent eleifend eros eu libero faucibus, sed cursus metus interdum.</p>
                                                <p class="tags-box">
                                                    <span class="article-tags">Neque</span>
                                                    <span class="article-tags">porro</span>
                                                    <span class="article-tags">quisquLorem</span>
                                                    <span class="article-tags">ipsum </span>
                                                    <span class="article-tags">dolor</span>
                                                </p>
                                                <div class="marketplace-price-box">
                                                    <p>Price: <span class="strong"> ₹3000</span></p>
                                                    <button>Buy Now</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-6 col-sm-12">
                                        <div class="webinar-box article-box">
                                            <!-- <a [href]="programme.redirect_link" target="_blank">
                                            </a> -->
                                            <div class="webinar-image-box">
                                                <img class="zoom-image"  src="/assets/images/default-blog.svg" alt="Programme Image">
                                            </div>
                                            <div class="webinar-content-box">
                                                <a class="post-title webinar-title" href="/fellowships/fellowships-title">Neque porro quisquam est qui dolorem ipsum quia dolor sit amet</a>
                                                <p class="post-description webinar-desc top-gap">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed at blandit ipsum. Curabitur laoreet venenatis lacus sed volutpat. Aenean vitae dictum sapien, in consectetur ex. Mauris sit amet tincidunt ex. In ac auctor dolor, eget dictum orci. Praesent eleifend eros eu libero faucibus, sed cursus metus interdum.</p>
                                                <p class="tags-box">
                                                    <span class="article-tags">Neque</span>
                                                    <span class="article-tags">porro</span>
                                                    <span class="article-tags">quisquLorem</span>
                                                    <span class="article-tags">ipsum </span>
                                                    <span class="article-tags">dolor</span>
                                                </p>
                                                <div class="marketplace-price-box">
                                                    <p>Price: <span class="strong"> ₹3000</span></p>
                                                    <button>Buy Now</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-6 col-sm-12">
                                        <div class="webinar-box article-box">
                                            <!-- <a [href]="programme.redirect_link" target="_blank">
                                            </a> -->
                                            <div class="webinar-image-box">
                                                <img class="zoom-image"  src="/assets/images/default-blog.svg" alt="Programme Image">
                                            </div>
                                            <div class="webinar-content-box">
                                                <a class="post-title webinar-title" href="/fellowships/fellowships-title">Neque porro quisquam est qui dolorem ipsum quia dolor sit amet</a>
                                                <p class="post-description webinar-desc top-gap">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed at blandit ipsum. Curabitur laoreet venenatis lacus sed volutpat. Aenean vitae dictum sapien, in consectetur ex. Mauris sit amet tincidunt ex. In ac auctor dolor, eget dictum orci. Praesent eleifend eros eu libero faucibus, sed cursus metus interdum.</p>
                                                <p class="tags-box">
                                                    <span class="article-tags">Neque</span>
                                                    <span class="article-tags">porro</span>
                                                    <span class="article-tags">quisquLorem</span>
                                                    <span class="article-tags">ipsum </span>
                                                    <span class="article-tags">dolor</span>
                                                </p>
                                                <div class="marketplace-price-box">
                                                    <p>Price: <span class="strong"> ₹3000</span></p>
                                                    <button>Buy Now</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-6 col-sm-12">
                                        <div class="webinar-box article-box">
                                            <!-- <a [href]="programme.redirect_link" target="_blank">
                                            </a> -->
                                            <div class="webinar-image-box">
                                                <img class="zoom-image"  src="/assets/images/default-blog.svg" alt="Programme Image">
                                            </div>
                                            <div class="webinar-content-box">
                                                <a class="post-title webinar-title" href="/fellowships/fellowships-title">Neque porro quisquam est qui dolorem ipsum quia dolor sit amet</a>
                                                <p class="post-description webinar-desc top-gap">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed at blandit ipsum. Curabitur laoreet venenatis lacus sed volutpat. Aenean vitae dictum sapien, in consectetur ex. Mauris sit amet tincidunt ex. In ac auctor dolor, eget dictum orci. Praesent eleifend eros eu libero faucibus, sed cursus metus interdum.</p>
                                                <p class="tags-box">
                                                    <span class="article-tags">Neque</span>
                                                    <span class="article-tags">porro</span>
                                                    <span class="article-tags">quisquLorem</span>
                                                    <span class="article-tags">ipsum </span>
                                                    <span class="article-tags">dolor</span>
                                                </p>
                                                <div class="marketplace-price-box">
                                                    <p>Price: <span class="strong"> ₹3000</span></p>
                                                    <button>Buy Now</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-6 col-sm-12">
                                        <div class="webinar-box article-box">
                                            <!-- <a [href]="programme.redirect_link" target="_blank">
                                            </a> -->
                                            <div class="webinar-image-box">
                                                <img class="zoom-image"  src="/assets/images/default-blog.svg" alt="Programme Image">
                                            </div>
                                            <div class="webinar-content-box">
                                                <a class="post-title webinar-title" href="/fellowships/fellowships-title">Neque porro quisquam est qui dolorem ipsum quia dolor sit amet</a>
                                                <p class="post-description webinar-desc top-gap">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed at blandit ipsum. Curabitur laoreet venenatis lacus sed volutpat. Aenean vitae dictum sapien, in consectetur ex. Mauris sit amet tincidunt ex. In ac auctor dolor, eget dictum orci. Praesent eleifend eros eu libero faucibus, sed cursus metus interdum.</p>
                                                <p class="tags-box">
                                                    <span class="article-tags">Neque</span>
                                                    <span class="article-tags">porro</span>
                                                    <span class="article-tags">quisquLorem</span>
                                                    <span class="article-tags">ipsum </span>
                                                    <span class="article-tags">dolor</span>
                                                </p>
                                                <div class="marketplace-price-box">
                                                    <p>Price: <span class="strong"> ₹3000</span></p>
                                                    <button>Buy Now</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-6 col-sm-12">
                                        <div class="webinar-box article-box">
                                            <!-- <a [href]="programme.redirect_link" target="_blank">
                                            </a> -->
                                            <div class="webinar-image-box">
                                                <img class="zoom-image"  src="/assets/images/default-blog.svg" alt="Programme Image">
                                            </div>
                                            <div class="webinar-content-box">
                                                <a class="post-title webinar-title" href="/fellowships/fellowships-title">Neque porro quisquam est qui dolorem ipsum quia dolor sit amet</a>
                                                <p class="post-description webinar-desc top-gap">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed at blandit ipsum. Curabitur laoreet venenatis lacus sed volutpat. Aenean vitae dictum sapien, in consectetur ex. Mauris sit amet tincidunt ex. In ac auctor dolor, eget dictum orci. Praesent eleifend eros eu libero faucibus, sed cursus metus interdum.</p>
                                                <p class="tags-box">
                                                    <span class="article-tags">Neque</span>
                                                    <span class="article-tags">porro</span>
                                                    <span class="article-tags">quisquLorem</span>
                                                    <span class="article-tags">ipsum </span>
                                                    <span class="article-tags">dolor</span>
                                                </p>
                                                <div class="marketplace-price-box">
                                                    <p>Price: <span class="strong"> ₹3000</span></p>
                                                    <button>Buy Now</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="pagination-container">
                    <!-- <pagination [totalItems]="totalProgrammes" [itemsPerPage]="12" [(ngModel)]="currentPage" (pageChanged)="loadMore($event)">
                    </pagination> -->
                </div>
            </div>
            
        </div>
    </div>
</section>
