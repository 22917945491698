<style>
    @font-face {
      font-family: "CooperHewitt";
      src: url("../../assets/fonts/cooper-hewitt/CooperHewitt-Light.otf");
      src: url("../../assets/fonts/cooper-hewitt/CooperHewitt-Light.otf")
        format("opentype");
      font-style: normal;
      font-weight: 300;
    }
    @font-face {
      font-family: "CooperHewitt";
      src: url("../../assets/fonts/cooper-hewitt/CooperHewitt-Medium.otf");
      src: url("../../assets/fonts/cooper-hewitt/CooperHewitt-Medium.otf")
        format("opentype");
      font-style: normal;
      font-weight: normal;
    }
    @font-face {
      font-family: "CooperHewitt";
      src: url("../../assets/fonts/cooper-hewitt/CooperHewitt-Semibold.otf");
      src: url("../../assets/fonts/cooper-hewitt/CooperHewitt-Semibold.otf")
        format("opentype");
      font-style: normal;
      font-weight: 600;
    }
    @font-face {
      font-family: "CooperHewitt";
      src: url("../../assets/fonts/cooper-hewitt/CooperHewitt-Bold.otf");
      src: url("../../assets/fonts/cooper-hewitt/CooperHewitt-Bold.otf")
        format("opentype");
      font-style: normal;
      font-weight: 700;
    }
    @font-face {
      font-family: "CooperHewitt";
      src: url("../../assets/fonts/cooper-hewitt/CooperHewitt-Heavy.otf");
      src: url("../../assets/fonts/cooper-hewitt/CooperHewitt-Heavy.otf")
        format("opentype");
      font-style: normal;
      font-weight: 800;
    }
    #contentToConvert * {
      font-family: "CooperHewitt";
    }
  </style>
  <div class="container" style="margin-top: 100px">
    <h1>HTML to PDF in Angular 9</h1>
    <button (click)="toPdf()">Generate PDF</button>
    <div id="contentToConvert" style="padding: 30px 0">
      <table
        width="800px"
        style="
          margin: 0 auto;
          background-image: url(../../assets/images/resources/pdf-bg.jpeg);
          background-repeat: no-repeat;
          background-position: center right;
          background-size: 100% 100%;
        "
      >
        <tbody>
          <tr>
            <td width="11%"></td>
            <td width="59%" style="padding: 30px 0">
              <table style="width: 100%">
                <tr>
                  <td>
                    <img src="../../assets/images/resources/label.png" width="96px" style="margin-bottom: 10px;" />
                  </td>
                  <td style="text-align: right; padding-right: 30px;">
                    <p
                      style="
                        font-weight: 600;
                        font-size: 28px;
                        margin-bottom: 10px;
                        line-height: 1.2;
                      "
                    >
                      www.collegey.com
                    </p>
                    <p
                      style="
                        font-weight: 300;
                        font-size: 21px;
                        margin-bottom: 5px;
                        line-height: 1.2;
                      "
                    >
                      India | USA
                    </p>
                  </td>
                </tr>
                <tr>
                  <td colspan="2">
                    <p
                      style="
                        font-weight: 700;
                        font-size: 32px;
                        margin-bottom: 0;
                        line-height: 1.2;
                        text-transform: uppercase;
                      "
                    >
                      collegey INC.
                    </p>
                    <p
                      style="
                        font-weight: 500;
                        font-size: 15px;
                        margin: 8px 0;
                        line-height: 1.2;
                        font-style: italic;
                      "
                    >
                      hereby recognizes that
                    </p>
                    <p
                      style="
                        font-weight: 700;
                        font-size: 32px;
                        margin-bottom: 8px;
                        line-height: 1.2;
                        font-style: italic;
                      "
                    >
                      Akshita Singh
                    </p>
                    <p
                      style="
                        font-weight: 400;
                        font-size: 15px;
                        margin-bottom: 5px;
                        line-height: 1.2;
                        letter-spacing: 0.5px;
                      "
                    >
                      from <b>Delhi Public School, RK Puram, New Delhi</b>
                    </p>
                    <p
                      style="
                        font-weight: 400;
                        font-size: 15px;
                        margin-bottom: 10px;
                        line-height: 1.2;
                        font-style: italic;
                      "
                    >
                      has successfully completed the <b>City Prototype</b> project
                      on <b>10/3/2022.</b>
                    </p>
                  </td>
                </tr>
              </table>
              <table style="width: 100%">
                <tr>
                  <td width="70%">
                    <p
                      style="
                        font-weight: 400;
                        font-size: 15px;
                        margin-bottom: 40px;
                        line-height: 1.2;
                        font-style: italic;
                        max-width: 276px;
                      "
                    >
                      Collegey Impact Project is a rigorous 4-week, UN SDG-aligned
                      project that includes training and mentorship with
                      peer-to-peer feedback, project management experience, and
                      design thinking pedagogy.
                    </p>
                    <p
                      style="
                        font-weight: normal;
                        font-size: 12px;
                        margin-bottom: 8px;
                        line-height: 1.2;
                      "
                    >
                      Authorized and issued by:
                    </p>
                    <p
                      style="
                        font-weight: 600;
                        font-size: 15px;
                        margin-bottom: 8px;
                        line-height: 1.2;
                        text-transform: uppercase;
                      "
                    >
                      collegey inc.
                    </p>
                    <p
                      style="
                        font-weight: normal;
                        font-size: 12px;
                        margin-bottom: 8px;
                        line-height: 1.2;
                      "
                    >
                      Tacoma, Washington, USA | Noida, UP, India
                    </p>
                  </td>
                  <td width="30%" style="text-align: right; vertical-align: bottom; padding-right: 30px;">
                    <img src="../../assets/images/resources/stamp.png" width="140px" />
                  </td>
                </tr>
              </table>
            </td>
            <td width="30%"></td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  