<br/>
<br/>
<br/>
<br/>
<br/>
<br/>
<br/>
<br/>
<div class="bingo-modal">
    <div class="text-box">
        <p class="p1">Bingo!</p>
        <p class="p2">You have just joined a Project Congratulations!</p>
        <p class="p3">Starting today, you have 30 days to complete the project before the offer expires.</p>
        <button style="background: #FF971A 0% 0% no-repeat padding-box;
    border-radius: 4px;font: normal normal bold 12px/16px Nunito;
letter-spacing: 0px;color: #FFFFFF;padding: 10px 50px;cursor: pointer;" (click)="navigateProjectSection()">START NOW</button>
    </div>
    <div class="bingo-img">
        <img src="./../../../assets/images/bingo.png" alt="">
    </div>
</div>

<br/>
<br/>
<br/>
<br/>