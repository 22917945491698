import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-collegey-fellowships',
  templateUrl: './collegey-fellowships.component.html',
  styleUrls: ['./collegey-fellowships.component.css']
})
export class CollegeyFellowshipsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
