<div class="modal-form-wrap" *ngIf="!resetLink">
    <button type="button" class="close-button" (click)="bsModalRef.hide();">
    <img src="/assets/images/cross-button.png"></button>
    <form [formGroup]="forgotPasswordForm" (ngSubmit)="onSubmit()">
        <div class="modal-form-body1">
            <p class="forgot-password-heading">Forgot Password</p>
            <p class="forgot-password-subheading">Please enter your email address and we'll send you a link to reset your password.
            </p>
            <div class="form-group">
                <input type="email" formControlName="email" class="form-control" placeholder="Enter Your Email" required>
                <app-input-validation [isShowErrorMsg]="showErrorMessage" [control]="forgotPasswordForm.get('email')">
                </app-input-validation>
            </div>
            <button type="submit" class="next-btn">Submit</button>
            <button (click)="openLoginModal()" type="button" class="back-btn">Back to Sign In</button>
        </div>
    </form>
</div>
<div class="modal-form-wrap reset-link-sent" *ngIf="resetLink">
    <button type="button" class="close-button" (click)="bsModalRef.hide();"><img src="/assets/images/cross-button.png"></button>
    <div class="modal-form-body1">
        <img src="/assets/images/green-circle-tick.svg" class="link-sent-image">
        <p class="forgot-password-heading">Reset Link Sent</p>
        <p class="forgot-password-subheading">Please check your email. Instructions were sent to your email address.</p>
    </div>
</div>